@font-face {
  font-family: firaSans;
  src: url(assets/fonts/FiraSans-Light.ttf);
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
}
*{
  font-size: 2vh;
   font-family: firaSans !important;
}

@media (max-width: 426px) {
  *{
    font-size: 1rem;
    font-family: firaSans !important;
  }
 }



a {
  color: inherit;
  text-decoration: none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

