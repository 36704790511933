body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*{
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
#root{
  min-height:100vh;
}

@keyframes onInputFocus {
  from {
    border: 0.2vh solid rgb(196, 196, 196);
    background-color: rgb(223, 223, 223);
  }
  to {
    border: 0.2vh solid rgb(216, 221, 225);
    background-color: white;
  }
}